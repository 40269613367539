import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import {FaEnvelope, FaLocationArrow, FaFacebook, FaLinkedin} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SiteImg1 from "../assets/img/component-site.png"



const CookiePolicy = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout pageData={""}>
        <SEO title="Cookie Policy" />


        <section className="contact-form-section">
          <h1>Cookie Policy</h1>
          
          <div className="full-width-paragraph">

          
          <h3 class="title-grey-faq-information">Information about cookies on our website</h3>
            <p class="grey-paragraph-faq" >Most websites you visit will use cookies in order to improve your user experience by enabling that website to ‘remember’ you, either for the duration of your visit (using a ‘session cookie’) or for repeat visits (using a ‘persistent cookie’).<br/>
            Cookies do lots of different jobs, like letting you navigate between pages efficiently, storing your preferences, and generally improving your experience of a website.<br/>
            Cookies make the interaction between you and the website faster and easier. If a website doesn’t use cookies, it will think you are a new visitor every time you move to a new page on the site.<br/>
            Some websites will also use cookies to enable them to target their advertising or marketing messages based for example, on your location and/or browsing habits.<br/>
            Cookies may be set by the website you are visiting (‘first party cookies’) or they may be set by other websites who run content on the page you are viewing (‘third party cookies’).</p>

            <h3 class="title-grey-faq-information">What is in a cookie?</h3>
            <p class="grey-paragraph-faq" >A cookie is a simple text file that is stored on your computer or mobile device by a website’s server and only that server will be able to retrieve or read the contents of that cookie. Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier and the site name and some digits and numbers. It allows a website to remember things like your preferences or what’s in your shopping basket.</p>


            <h3 class="title-grey-faq-information">What to do if you don’t want cookies to be set?</h3>
            <p class="grey-paragraph-faq" >Some people find the idea of a website storing information on their computer or mobile device a bit intrusive, particularly when this information is stored and used by a third party without them knowing. Although this is generally quite harmless you may not, for example, want to see advertising that has been targeted to your interests. If you prefer, it is possible to block some or all cookies, or even to delete cookies that have already been set, but you need to be aware that you might lose some functions of that website.</p>

            <h3 class="title-grey-faq-information">How do I disable cookies?</h3>
            <p class="grey-paragraph-faq" >If you want to disable cookies you need to change your website browser settings to reject cookies. How to do this will depend on the browser you use and we provide further detail below on how to disable cookies for the most popular browsers:<br/>
            For Microsoft Internet Explorer:<br/>

                1. Choose the menu “tools” then “Internet Options”<br/>
                2. Click on the “privacy” tab<br/>
                3. Select the setting the appropriate setting<br/>

            For Mozilla Firefox:<br/>

                1. Choose the menu “tools” then “Options”<br/>
                2. Click on the icon “privacy”<br/>
                3. Find the menu “cookie” and select the relevant options<br/>

            For Opera 6.0 and further:<br/>

                1. Choose the menu Files”&gt; “Preferences”<br/>
                2. Privacy<br/>
            </p>

            <h3 class="title-grey-faq-information">How we use cookies</h3>
            <p class="grey-paragraph-faq" >We use cookies that are strictly necessary to enable you to move around the site or to provide certain basic features. We use cookies to enhance the functionality of the website by storing your preferences. We also use cookies to help us improve the performance of our website and to provide you with a better user experience.</p>

            <h3 class="title-grey-faq-information">Google Analytics’ cookies</h3>
            <p class="grey-paragraph-faq" >Every time a user visits our website, Google Analytics generates anonymous analytics cookies. These cookies can tell us whether or not you’ve visited the site before. Your browser will tell us if you have these cookies, and if you don’t, we generate new ones. This allows us to track how many individual unique users we have, and how often they visit the site.</p>

            <h3 class="title-grey-faq-information">Advertising cookies</h3>
            <p class="grey-paragraph-faq" >We also use cookies to assist in targeted advertising. We use cookies to more accurately target advertising to you, to show more relevant ads online. These cookies are anonymous. If you receive one of those cookies, we may then use it to identify you as having visited our site if you later visit other sites that are part of the Google advertising network, and will serve targeted advertising based on this information.</p>

            <h3 class="title-grey-faq-information">ASP.NET Session Cookie</h3>
            <p class="grey-paragraph-faq" >These cookies are used to contain required information for the application to work, ASP.NET uses to store a unique identifier for your session. The session cookie is not persisted on your hard disk.<br/>
            We don’t sell the information collected by cookies, nor do we disclose the information to third parties, except where required by law (for example to government bodies and law enforcement agencies).</p>
          </div>

        </section>


      </Layout>

    )}
  
  />
)

export default CookiePolicy
